@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
}

.each-slide {
  height:50vh;
}
.slider {
  height:50vh;
}
.slide-container {
position: relative;
}
.logo {
  position: absolute;
  z-index: 1000;
  top:20px;

}
html {
  scroll-behavior: smooth;
}
.logoimg {
  position: absolute;
  z-index: 1000;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
     
  input[type="range"]::-webkit-slider-thumb {
      width: 15px;
      -webkit-appearance: none;
  appearance: none;
      height: 15px;
      cursor: ew-resize;
      background: #84cc16;
  
      border-radius: 50%;
      
  }
}
